import 'bootstrap/dist/js/bootstrap.bundle';

import $ from "jquery";


$(function() {
    $(".searchIcon").on("click",function() {
      $(".search").toggleClass("active");
      $(this).toggle()
      $(this).siblings().toggle()
    });
  });